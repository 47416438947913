import { ifNotAuthenticated, ifAuthenticated } from "@/router/auth";

import Login from "@/views/Login.vue";
import Logout from "@/views/Logout.vue";
import Home from "@/views/Home.vue";
import Reports from "@/views/Reports.vue";

import NotFound from "@/views/NotFound.vue";

const main = [
    {
      path: "/login/:pid",
      name: "Login",
      component: Login,
      beforeEnter: ifNotAuthenticated,
      meta: { moduleId: "login" },
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
      beforeEnter: ifAuthenticated,
      meta: { moduleId: "logout" },
    },
    {
      path: "/",
      name: "Home",
      component: Home,
      beforeEnter: ifAuthenticated,
      meta: { moduleId: "home" },
    },
    {
      path: "/reports",
      name: "Reports",
      component: Reports,
      beforeEnter: ifAuthenticated,
      meta: { moduleId: "reports" },
    },
    {
      path: "*",
      name: "NotFound",
      component: NotFound,
      meta: { moduleId: "notfound" },
    },
  ];

  export default main;
