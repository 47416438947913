<template>
  <div>
    <b-navbar type="is-black" transparent>
      <template slot="end">
         <b-navbar-item tag="div" class="has-text-grey">
          [v{{version}}]
        </b-navbar-item>
        <b-navbar-item href="#" class="has-text-primary"  @click="isSiteNoticeModalActive = true">
          Impressum
        </b-navbar-item>
        <b-navbar-item
          href="#"
          @click="isPrivacyModalActive = true"
          class="has-text-primary"
        >
          Datenschutz
        </b-navbar-item>
      </template>
    </b-navbar>
    <section class="hero is-fullheight-with-navbar has-background-black">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-4 is-offset-4">
              <div class="box">
                <article class="media">
                  <div class="media-content">
                    <div class="content">
                      <p class="has-text-centered">
                        <i
                          class="fal fa-business-time has-text-primary"
                          style="font-size: 5rem"
                        ></i>
                      </p>

                      <b-field
                        label="Profil-ID"
                        :type="hasError('pid')"
                        :message="errorText('pid')"
                      >
                        <b-input
                          v-model="pid"
                          :disabled="isLoading"
                          v-on:keyup.enter.native="login"
                        ></b-input>
                      </b-field>
                      <b-field
                        label="Passwort"
                        :message="errorText('password')"
                        :type="hasError('password')"
                      >
                        <b-input
                          type="password"
                          password-reveal
                          v-model="password"
                          :disabled="isLoading"
                          v-on:keyup.enter.native="login"
                        ></b-input>
                      </b-field>
                      <b-button
                        type="is-primary has-text-dark has-text-weight-bold"
                        class="mt-5"
                        expanded
                        @click="login"
                        :loading="isLoading"
                        >Anmelden</b-button
                      >
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

   
    <ModalPrivacy
      :isPrivacyModalActive="isPrivacyModalActive"
      @onCancel="onPrivacyModalClose"
    />

     <ModalSiteNotice
      :isSiteNoticeModalActive="isSiteNoticeModalActive"
      @onCancel="onSiteNoticeModalClose"
    />
  </div>
</template>

<script>
import ModalPrivacy from "@/components/ModalPrivacy.vue";
import ModalSiteNotice from "@/components/ModalSiteNotice.vue";

import _ from "lodash";

export default {
  name: "LoginPage",
  components: {
    ModalPrivacy,
    ModalSiteNotice
  },
  data() {
    return {
      pid: "",
      password: "",
      isPrivacyModalActive: false,
      isSiteNoticeModalActive: false,
    };
  },
  methods: {
    login: function () {
      const { pid, password } = this;
      this.$store
        .dispatch("auth/login", { pid, password })
        .then(() => {
          this.$router.push("/");
        })
        .catch((response) => {
          this.$buefy.toast.open({
            message: response.data.payload.errorMsg,
            type: "is-danger",
          });
        });
    },
    errorText(field) {
      return this.$store.state.auth.errors[field];
    },
    hasError(field) {
      return !_.isEmpty(this.$store.state.auth.errors[field])
        ? "is-danger"
        : "";
    },
    setAutoPID() {
      const pid = this.$route.params.pid;
      this.pid = pid !== "-" ? pid : "";
    },
    onPrivacyModalClose() {
      this.isPrivacyModalActive = false;
    },
    onSiteNoticeModalClose() {
      this.isSiteNoticeModalActive = false;
    },
  },

  computed: {
    isLoading() {
      return this.$store.state.auth.loading;
    },
    version() {
      return this.$store.getters.version;
    }
  },
  mounted: function () {
    this.setAutoPID();
  },
};
</script>

<style scoped>
.hero.has-background {
  position: relative;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.hero-background.is-transparent {
  opacity: 0.8;
}
</style>