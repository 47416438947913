const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setData(state, payload) {
    state.data = payload.data;
  },
  setCreateInProcess(state, inProcess) {
    state.create.inProcess = inProcess;
  },
  setCreateErrors(state, errors) {
    state.create.errors = errors;
  },
};

export default mutations;
