import Vue from 'vue'
import VueRouter from 'vue-router'

import main from "@/router/main";

Vue.use(VueRouter)

const routes = [...main];

const router = new VueRouter({
  routes,
scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

export default router
