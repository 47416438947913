<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active="isLoading || isCreateInProcess"
      :can-cancel="false"
    ></b-loading>
    <TopNavbar />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <p class="title">
            {{ profileTitle }} ({{ profileStart }} - {{ profileEnd }})
          </p>
          <p class="subtitle">{{ employee }}</p>
        </div>
      </div>
      <div class="hero-foot">
        <nav class="tabs is-boxed is-fullwidth">
          <div class="container">
            <ul>
              <li class="has-text-weight-semibold" style="color: #000;"><router-link to="/">Übersicht</router-link></li>
              <li class="is-active has-text-weight-semibold" style="color: #000;">
                <router-link to="/reports">Bericht</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <p class="title is-4">Verfügbare Zeiträume</p>
              <b-message  type="is-dark" icon="info-circle" has-icon size="is-normal" :closable="false">
                Hier druckst Du die Wochenberichte über die erfasste Arbeitszeit
                sowie die erhaltenen Urlaubs- und Schulungstage aus. Hinweis:
                Der Nachweis ist nur verbindlich, wenn Du und Dein Beschäftigter
                den Nachweis gegenzeichnen. Drucke deshalb eine Fassung für Dich
                und eine Fassung für Deinen Beschäftigten aus.
              </b-message>
              <b-table :data="data" hoverable striped>
                <b-table-column field="period" label="Arbeitswoche">
                  <template v-slot:header="{ column }">
                    {{ column.label }}
                  </template>
                  <template v-slot="props">
                    {{ props.row.period }}
                  </template>
                </b-table-column>
                <b-table-column label="Ausgewählt" numeric>
                  <template v-slot:header="{ column }">
                    {{ column.label }}
                  </template>
                  <template v-slot="props">
                    <b-switch
                      v-model="props.row.selected"
                      type="is-success"
                    ></b-switch>
                  </template>
                </b-table-column>
                <template slot="empty">
                  <div class="has-text-centered py-5" v-if="!isLoading">
                    Es sind keine Daten vorhanden!
                  </div>
                  <div class="has-text-centered py-5" v-else>
                    Daten werden geladen!
                  </div>
                </template>
              </b-table>
              <div class="columns is-centered" v-if="!isLoading">
                <div class="column is-3">
                  <b-button
                    class="mt-6"
                    expanded
                    type="is-success"
                    :disabled="isButtonDisabled"
                    @click="createReport"
                    >Bericht erzeugen</b-button
                  >
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TopNavbar from "@/components/TopNavbar.vue";

import _ from "lodash";

import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("reports");

export default {
  name: "ReportsPage",

  components: {
    TopNavbar,
  },
  methods: {
    UTCToDate(value) {
      const date = new Date(value);
      return new Intl.DateTimeFormat("de-DE", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(date);
    },
    list: function () {
      this.$store.dispatch("reports/list").catch((response) => {
        this.$buefy.toast.open({
          message: response.data.payload.errorMsg,
          type: "is-danger",
        });
      });
    },
    createReport: function () {
      const keyList = this.getSelectedKeys;

      this.$store
        .dispatch("reports/create", {
          keyList,
        })
        .then((response) => {
          this.$buefy.dialog.confirm({
            title: "Bericht",
            message:
              "Dieser wurde soeben fertiggestellt und kann nun abgerufen werden!",
            cancelText: "Abbrechen",
            confirmText: "Anzeigen",
            type: "is-success",
            onConfirm: () => {
              window.open(response.data.payload.url, "_blank");
            },
            onCancel: () => {
              this.list();
            },
          });
        })
        .catch((response) => {
          this.$buefy.toast.open({
            message: response.data.payload.errorMsg,
            type: "is-danger",
          });
        });
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.loading,
      isCreateInProcess: (state) => state.create.inProcess,
      data: (state) => state.data,
    }),
    employee() {
      return this.$store.getters.employee;
    },
    profileTitle() {
      return this.$store.getters.profile.title;
    },
    profileStart() {
      return this.UTCToDate(this.$store.getters.profile.start);
    },
    profileEnd() {
      return this.UTCToDate(this.$store.getters.profile.end);
    },
    isButtonDisabled() {
      if (_.isEmpty(this.data)) {
        return true;
      }

      return this.data.filter((item) => item.selected === true).length > 0
        ? false
        : true;
    },
    getSelectedKeys() {
      return this.data
        .filter((item) => item.selected === true)
        .map((item) => {
          return item.id;
        });
    },
  },
  mounted: function () {
    this.list();
  },
};
</script>

<style>
</style>