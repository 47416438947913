import axios from "axios";

const get = (url, params = {}) =>
  new Promise((resolve, reject) => {
    axios
      .get("https://api.zds-arbeitszeit.de" + url, { params, timeout: 60000 })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

const post = (url, postData = {}) =>
  new Promise((resolve, reject) => {
    axios
      .post("https://api.zds-arbeitszeit.de" + url, postData, { timeout: 60000 })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export default {
  get,
  post,
};
