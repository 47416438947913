import api from "@/api";

const actions = {
  list({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      commit("setErrors", []);
      commit("setData", []);
      api
        .get("/v1/reports")
        .then((response) => {
          if (response.data.success) {
            commit("setData", response.data.payload);
            commit("setLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          commit("setData", []);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  create({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setCreateInProcess", true);
      commit("setCreateErrors", []);
      api
        .post("/v1/reports", data)
        .then((response) => {
          if (response.data.success) {
            commit("setCreateInProcess", false);
            resolve(response);
          } else {
            const { errors } = response.data.payload;

            commit("setCreateErrors", errors);
            commit("setCreateInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setCreateInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
