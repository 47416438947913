const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setData(state, payload) {
    state.data = payload.data;
  },
  setDateCalendar(state, value) {
    state.dateCalendar = value;
  },
  setMonthCalendar(state, value) {
    state.monthCalendar = value;
  },
};

export default mutations;
