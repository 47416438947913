import Vue from "vue";
import Vuex from "vuex";

import router from "@/router";

import auth from "@/store/modules/auth/";
import home from "@/store/modules/home/";
import reports from "@/store/modules/reports/";

import _ from "lodash";

Vue.use(Vuex);

const loadLoginData = () => {
  const loginDataJson = localStorage.getItem("loginData");
  if (loginDataJson) {
    const loginData = JSON.parse(loginDataJson);
    return loginData;
  } else {
    return "";
  }
};

export default new Vuex.Store({
  state: {
    version: "0.2.1",
    loginData: loadLoginData(),
  },
  mutations: {
    setLoginData(state, loginData) {
      state.loginData = loginData;
    },
  },
  actions: {
    gobalError({ commit }, err) {
      const httpStatus = err.response.status;
      let errorMsg = "";

      if (httpStatus == 401) {
        localStorage.removeItem("loginData");
        commit("setLoginData", null);
        router.push("/login/-");
        errorMsg = "Es ist eine Problem mit der Sitzung aufgetreten!";
      } else {
        errorMsg = "Fehler bei Kommunikation mit der API!";
      }

      this._vm.$buefy.toast.open({
        message: errorMsg,
        type: "is-danger",
      });
    },
  },
  getters: {
    isAuthenticated: (state) => !_.isEmpty(state.loginData),
    version: (state) => {
      return state.version;
    },
    employee: (state) => {
      return state.loginData.employee;
    },
    profile: (state) => {
      return state.loginData.profile;
    },
  },
  modules: {
    auth,
    home,
    reports,
  },
});
