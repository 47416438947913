<template>
  <b-navbar type="is-black">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <i class="fal fa-business-time has-text-primary is-size-3"></i>
        <p class="ml-2 is-size-4 has-text-weight-bold">&nbsp;</p></b-navbar-item
      >
    </template>

    <template slot="end">
      <b-navbar-dropdown label="Informationen">
        <b-navbar-item href="#" @click="modalSiteNoticeOpen()">
          Impressum
        </b-navbar-item>
        <b-navbar-item href="#" @click="modalPrivacyOpen()">
          Datenschutz
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button
            icon-right="sign-out-alt"
            tag="router-link"
            to="/logout"
            type="is-danger"
          >
            Abmelden
          </b-button>
        </div></b-navbar-item
      >
    </template>
  </b-navbar>
</template>>

<script>
export default {
  name: "TopNavbar",
  components: {},
  methods: {
    modalPrivacyOpen() {
      this.$emit("onPrivacyOpenClick");
    },
    modalSiteNoticeOpen() {
      this.$emit("onSiteNoticeClick");
    },
  },
  computed: {
    version() {
      return this.$store.getters.version;
    },
  },
};
</script>

<style scoped>
</style>