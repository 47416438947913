<template>
  <section class="hero is-fullheight omnia-special-grey">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-one-third is-offset-one-third">
            <div class="box has-background-white">
              <article class="media">
                <div class="media-content">
                  <div class="content has-text-centered">
                    <p>Leider wurde die Seite nicht gefunden!</p>
                    <section>
                      <b-button @click="goback">Zurück</b-button>
                    </section>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>