<template>
  <b-modal :active="isPrivacyModalActive" @close="onModalClose">
    <section class="section">
      <div class="box">
        <div class="content">
          <h2 class="title">Datenschutzerklärung</h2>
          <h4>Allgemeine Informationen, Protokolldaten</h4>
          <p>
            Sie können die Websites des ZDS e.V. grundsätzlich besuchen, ohne
            uns mitzuteilen, wer Sie sind. Wir erfahren dann nur automatisch die
            folgenden Protokolldaten:
          </p>
          <ul>
            <li>Ihre IP-Adresse</li>
            <li>
              das von Ihnen genutzte Betriebssystem, den von Ihnen genutzten
              Web-Browser und die von Ihnen eingestellte Bildschirmauflösung,
            </li>
            <li>das Datum und die Uhrzeit Ihres Besuchs,</li>
            <li>die Websites, die Sie bei uns besucht haben und</li>
            <li>die Website, von der Sie uns besuchen.</li>
          </ul>
          <h4>
            Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten
          </h4>
          <p>
            Zu Ihren personenbezogenen Daten gehören Informationen wie Ihr Name,
            Ihre Adresse, Ihre Telefonnummer oder Ihre E-Mail-Adresse.
            Personenbezogene Daten werden nur erhoben, verarbeitet und/oder
            genutzt, wenn Sie uns diese von sich aus, z. B. zur Begründung,
            inhaltlichen Ausgestaltung oder Änderung eines zwischen Ihnen und
            uns geschlossenen Vertragsverhältnisses oder der Registrierung für
            personalisierte Dienste mitteilen oder wenn Sie uns eine Anfrage
            über die Website zukommen lassen. Darüber hinaus erfolgt eine
            Nutzung Ihrer personenbezogenen Daten nur, wenn Sie zuvor Ihre
            Einwilligung z. B. für den Erhalt aller Leistungen erteilt haben.
          </p>
          <p>
            Auf dieser Website können Sie eine Kontaktanfrage stellen. Die in
            diesem Zusammenhang angegebenen personenbezogenen Daten werden nur
            für die Erbringung des jeweiligen Dienstes im Umfang Ihrer
            Einwilligung erhoben, verarbeitet und genutzt. Sie können Ihre
            Einwilligung jederzeit unentgeltlich mit Wirkung für die Zukunft
            gegenüber des ZDS e.V. widerrufen z. B. über die folgende E-Mail
            Adresse
            <a href="mailto:datenschutz@zds-schornsteinfeger.de"
              >datenschutz@zds-schornsteinfeger.de</a
            >
          </p>
          <h4>Datenweitergabe</h4>
          <p>
            Eine Weitergabe personenbezogener Daten an Dritte erfolgt nur,
            soweit dies, insbesondere für die Vertragsabwicklung, notwendig ist.
            Dies ist z. B. der Fall, wenn Sie mit Hilfe des Mitgliedsantrages
            Mitglied im ZDS e.V. und die Mitgliedskonditionen bei der SIB
            Service GmbH, der Schornsteinfeger Verlag GmbH oder die
            Handwerksschule e.V. nutzen möchten.
          </p>
          <h4>Ihre Rechte</h4>
          <p>
            Sie haben ein jederzeitiges Auskunfts-, Berichtigungs- und
            Löschungsrecht bezüglich der Verarbeitung und Nutzung Ihrer
            personenbezogenen Daten. Diese Rechte können Sie gegenüber dem ZDS
            e.V. unentgeltlich über die E-Mail-Adresse
            <a href="mailto:datenschutz@zds-schornsteinfeger.de"
              >datenschutz@zds-schornsteinfeger.de</a
            >
            oder die im Impressum angegebenen Kontaktdaten geltend machen.
          </p>
          <h4>Häufige Fragen zum Datenschutz.</h4>
          <p>
            Sie möchten wissen, wie der ZDS e.V. Ihre Daten verwendet oder Sie
            haben andere Fragen rund um den Datenschutz? Auf den folgenden
            Seiten finden Sie alle grundlegenden Antworten!
          </p>
          <p>
            <strong>1. Was sind personenbezogene Daten?</strong><br />
            Unter personenbezogenen Daten sind Informationen zu verstehen, die
            Ihrer Person zugeordnet werden können. Darunter fällt zum Beispiel
            Ihr Name, Ihre Anschrift, Ihre Telefonnummer und Ihre
            E-Mail-Adresse. Informationen, die nicht direkt mit Ihrer Person in
            Verbindung gebracht werden, gehören nicht dazu. Wenn Sie bei uns
            Mitglied werden möchten oder eine Anfrage per Kontaktformular
            stellen, fragen wir Sie nach bestimmten personenbezogenen Daten.
            Diese Daten sind für die Anmeldung erforderlich und notwendig.
            Selbstverständlich können Sie Ihre Daten auch jederzeit löschen.
            Sofern zwingende Datenangaben von der Löschung betroffen sind, führt
            dies jedoch dazu, dass der personalisierte Dienst nicht mehr genutzt
            werden kann.
          </p>
          <p>
            <strong>2. Wie werden meine Daten vom ZDS e.V. verwendet?</strong
            ><br />
            Die im Rahmen der Webseite erhobenen personenbezogenen Daten werden
            ohne Ihre Einwilligung nur zur Vertragsabwicklung und Bearbeitung
            Ihrer Anfragen verarbeitet und genutzt. Darüber hinaus erfolgt eine
            Verarbeitung und Nutzung Ihrer Daten für Zwecke der Werbung und zur
            bedarfsgerechten Gestaltung der elektronischen Dienste des ZDS e.V.
            nur, wenn Sie hierzu zuvor Ihre Einwilligung erteilt haben. Das
            gleiche gilt für die Verarbeitung und Nutzung Ihrer Daten zum Zwecke
            von persönlich auf Sie zugeschnittene Werbung.
          </p>
          <p>
            <strong>3. Was sind Cookies?</strong><br />
            Ein Cookie ist ein kleiner Datensatz, der auf Ihrer Festplatte
            abgelegt werden kann. Dieser Datensatz wird von dem Web-Server, mit
            dem Sie über Ihren Web-Browser (z. B. Internet Explorer, Google
            Chrome) eine Verbindung aufgebaut haben, erzeugt und dann an Sie
            gesendet. Durch das Cookie können Sie beim Besuch der Webseite
            wiedererkannt werden, ohne dass Daten, die Sie bereits zuvor
            eingegeben haben, nochmals eingegeben werden müssen. Die meisten
            Browser sind zur Zeit standardmäßig so eingestellt, dass Sie Cookies
            automatisch akzeptieren. Sie haben jedoch die Möglichkeit, Ihren
            Browser auf die Ablehnung von Cookies oder deren vorherige Anzeige
            einzustellen. Darüber hinaus können Sie Cookies auch jederzeit auf
            Ihrem System (z. B. im Windows Explorer) löschen. Bitte benutzen Sie
            hierfür die Hilfefunktion in Ihrem Betriebssystem.
          </p>
          <p>
            <strong>4. Verwendet der ZDS e.V. Cookies?</strong><br />
            Ja, wir verwenden auf unserer Webseite Cookies. Im Allgemeinen
            setzen wir Cookies zur Analyse des Interesses an unseren Webseiten
            ein. Unsere Cookies bei den personalisierten Diensten, enthalten
            keinerlei persönliche Daten und werden nur verwendet, um Ihnen einen
            möglichst bequemen und sichereren Service bieten zu können.
          </p>
          <p>
            <strong>5. Habe ich ein Auskunftsrecht?</strong><br />
            Natürlich haben Sie jederzeit einen Anspruch darauf zu erfahren,
            welche personenbezogenen Daten wir über Sie schriftlich und digital
            speichern. Dazu wenden Sie sich bitte an den Datenschutzbeauftragten
            des ZDS e.V. .
          </p>
          <p>
            <strong
              >6. Habe ich ein Recht auf Datenänderung bzw. - löschung?</strong
            ><br />
            Sie haben jederzeit das Recht, Ihre Einwilligung zur Datenverwendung
            teilweise oder vollständig mit Wirkung für die Zukunft zu
            widerrufen. In diesem Fall werden wir Ihre entsprechenden Daten
            löschen. Ausnahmsweise können einer Löschung jedoch Gesetze,
            insbesondere im Hinblick auf Daten für Abrechnungs- und
            buchhalterische Zwecke, entgegenstehen.
          </p>
          <p>
            <strong>7. Werden meine Daten an Dritte weitergegeben?</strong
            ><br />
            Ihre Daten werden grundsätzlich nicht an Dritte übermittelt. Eine
            Weitergabe an Dritte oder andere in der Einwilligungserklärung
            genannte Dritte erfolgt nur mit Ihrer Zustimmung und soweit sich
            diese Empfänger zur Einhaltung der geltenden datenschutzrechtlichen
            Bestimmungen gegenüber dem ZDS e.V. verpflichtet haben. Im Rahmen
            der Bearbeitung Ihrer Anfragen und Ihrer Nutzung der vollen Leistung
            einer ZDS Mitgliedschaft beauftragen wir Dienstleister. Diese
            Dienstleister sind vertraglich zur Einhaltung datenschutzrechtlichen
            Vorschriften verpflichtet und gelten nicht als Dritte im
            datenschutzrechtlichen Sinne.
          </p>
          <p>
            <strong
              >8. Gilt diese Datenschutzerklärung auch für Webseiten anderer
              Anbieter?</strong
            ><br />
            Diese Erklärungen zum Datenschutz gelten nicht für die Webseiten
            anderer Anbieter, die über Links auf der Webseite aufgesucht werden
            können. Bitte beachten Sie deshalb die jeweiligen Regelungen anderer
            Anbieter zum Datenschutz.
          </p>
          <p>
            <strong
              >9. Wie sieht es mit der technischen Sicherheit bei der
              Übermittlung meiner Daten aus?</strong
            ><br />
            Personenbezogene, vom ZDS e.V. gespeicherte Daten werden in sicheren
            Betriebsumgebungen gespeichert und sind der Öffentlichkeit nicht
            zugänglich.
          </p>
          <p>
            <strong>10. Werden meine Verbindungsdaten gespeichert?</strong
            ><br />
            Der ZDS e.V. speichert beim Besuch der Webseiten auch Ihre
            Verbindungsdaten (wie z. B. Ihre IP-Adresse etc.). Diese verarbeiten
            oder nutzen wir über das Ende der Verbindung hinaus nur, soweit sie
            zum Aufbau weiterer Verbindungen, ggfs. zu Abrechnungszwecken, zur
            Ermittlung von Störungen von Telekommunikationsanlagen und
            Ermittlung von Missbrauch unserer Telekommunikationsdienste
            erforderlich sind.
          </p>
          <p>
            <strong>11. Änderung dieser Datenschutzerklärung</strong><br />
            Die schnelle Entwicklung des Internet macht es erforderlich, dass
            wir von Zeit zu Zeit Anpassungen an unserer Datenschutzerklärung
            vornehmen. Sofern hiervon auch die jeweiligen Inhalte Ihrer
            Einwilligungserklärung betroffen sind, werden wir Sie hierauf
            aufmerksam machen. Bitte beachten Sie diesbezüglich die jeweils
            aktuelle Version unserer Datenschutzerklärung.
          </p>
          <p>
            <strong
              >12. Ansprechpartner bei Fragen oder Auskunftsersuchen</strong
            ><br />
            Bei Fragen, Kommentaren, Beschwerden sowie Auskunftsersuchen im
            Zusammenhang mit unserer Erklärung zum Datenschutz und der
            Verarbeitung Ihrer persönlichen Daten können Sie sich an den
            Datenschutzbeauftragten des ZDS e.V. wenden, der Ihnen unter
            folgender E-Mail-Adresse zur Verfügung steht:
            <a href="mailto:datenschutz@zds-schornsteinfeger.de"
              >datenschutz@zds-schornsteinfeger.de</a
            >
          </p>
        </div>
      </div>
    </section>
  </b-modal>
</template>>

<script>
export default {
  props: ["isPrivacyModalActive"],
  name: "ModalPrivacy",
  methods: {
    onModalClose() {
      this.$emit("onCancel");
    },
  },
};
</script>

<style scoped>
</style>