const state = () => ({
  errors: [],
  loading: false,
  data: [],
  create: {
    errors: [], 
    inProcess: false,
  }
});

export default state;
