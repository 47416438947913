import axios from "axios";
import api from "@/api";

const actions = {
  login({ commit, dispatch }, userData) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      commit("setErrors", []);
      api
        .post("/v1/login", userData)
        .then((response) => {
          if (response.data.success) {
            const { loginData } = response.data.payload;
            axios.defaults.headers.common["Authorization"] = loginData.token;
            localStorage.setItem("loginData", JSON.stringify(loginData));
            commit("setLoginData", loginData, {
              root: true,
            });
            commit("setLoading", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setLoading", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setLoading", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      api
        .post("/v1/logout")
        .then((response) => {
          if (response.data.success) {
            localStorage.removeItem("loginData");
            commit("setLoginData", null, { root: true });
            delete axios.defaults.headers.common["Authorization"];
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
