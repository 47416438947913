<template>
  <b-modal :active="isSiteNoticeModalActive" @close="onModalClose">
    <section class="section">
      <div class="box">
        <div class="content">
          <h2 class="title">Angaben gemäß § 5 TMG:</h2>

          <p>
            Zentralverband Deutscher Schornsteinfeger e.V.<br />
            - Gewerkschaft &amp; Fachverband -
          </p>

          <p>
            Geschäftsstelle:<br />
            Konrad Zuse Straße 19<br />
            99099 Erfurt
          </p>
          <p>
            Telefon: <a href="tel:0361789510">0361 78951-0</a><br />
            Telefax: 0361 78951-20
          </p>

          <p>
            E-Mail:
            <a href="mailto:info@zds-schornsteinfeger.de"
              >info@zds-schornsteinfeger.de</a
            ><br />
            Internet:
            <a href="https://www.zds-schornsteinfeger.de" target="_blank"
              >www.zds-schornsteinfeger.de</a
            >
          </p>

          <p>
            Steuernummer: 151/143/51064<br />
            Amtsgericht: Erfurt VR 162145<br />
            Vertreten durch: Daniel Fürst (1.Vorsitzender), Maximilian Weber, Norman Wegert
          </p>

          <p>
            Sämtliche verfassten Texte und Bilder sind Eigentum des
            Zentralverbandes Deutscher Schornsteinfeger e.V. und dürfen nicht
            ohne vorherige schriftliche Genehmigung wiedergegeben oder
            veröffentlicht werden.
          </p>
          <h4>Rechtlicher Hinweis</h4>
          <p>
            Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden,
            dass man durch die Ausbringung eines Links die Inhalte der gelinkten
            Seite ggf. mit zu verantworten hat. Dies kann - so das LG - nur
            dadurch verhindert werden, dass man sich ausdrücklich von diesen
            Inhalten distanziert. Wir haben auf unserer Site Links zu anderen
            Seiten im Internet gelegt. Für all diese Links gilt: Wir möchten
            ausdrücklich betonen, dass wir keinerlei Einfluss auf die Gestaltung
            und die Inhalte der gelinkten Seiten haben. Deshalb distanzieren wir
            uns hiermit ausdrücklich von den Inhalten aller gelinkten Seiten auf
            dieser Homepage. Diese Erklärung gilt für alle auf unsere Homepage
            angebrachten Links und für alle Inhalte der Seiten, zu denen die
            Links führen.
          </p>

          <h4>Haftungsausschluß</h4>
          <p>
            <strong>1. Inhalt des Onlineangebotes</strong><br />
            Der Autor übernimmt keinerlei Gewähr für die Aktualität,
            Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
            Informationen. Haftungsansprüche gegen den Autor, welche sich auf
            Schäden materieller oder ideeller Art beziehen, die durch die
            Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch
            die Nutzung fehlerhafter und unvollständiger Informationen
            verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens
            des Autors kein nachweislich vorsätzliches oder grob fahrlässiges
            Verschulden vorliegt. Alle Angebote sind freibleibend und
            unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
            Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
            verändern, zu ergänzen, zu löschen oder die Veröffentlichung
            zeitweise oder endgültig einzustellen.
          </p>
          <p>
            <strong>2. Verweise und Links</strong><br />
            Bei direkten oder indirekten Verweisen auf fremde Webseiten
            ("Hyperlinks"), die außerhalb des Verantwortungsbereiches des Autors
            liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall
            in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und
            es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
            rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit
            ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen
            Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
            aktuelle und zukünftige Gestaltung, die Inhalte oder die
            Urheberschaft der verlinkten/verknüpften Seiten hat der Autor
            keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich
            von allen Inhalten aller verlinkten /verknüpften Seiten, die nach
            der Linksetzung verändert wurden. Diese Feststellung gilt für alle
            innerhalb des eigenen Internetangebotes gesetzten Links und Verweise
            sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern,
            Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen
            anderen Formen von Datenbanken, auf deren Inhalt externe
            Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder
            unvollständige Inhalte und insbesondere für Schäden, die aus der
            Nutzung oder Nichtnutzung solcherart dargebotener Informationen
            entstehen, haftet allein der Anbieter der Seite, auf welche
            verwiesen wurde, nicht derjenige, der über Links auf die jeweilige
            Veröffentlichung lediglich verweist.
          </p>
          <p>
            <strong>3. Urheber- und Kennzeichenrecht</strong><br />
            Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
            verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte
            zu beachten, von ihm selbst erstellte Bilder, Grafiken,
            Tondokumente, Videosequenzen und Texte zu nutzen oder auf
            lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte
            zurückzugreifen. Alle innerhalb des Internetangebotes genannten und
            ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen
            uneingeschränkt den Bestimmungen des jeweils gültigen
            Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
            Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss
            zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt
            sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte
            Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung
            oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und
            Texte in anderen elektronischen oder gedruckten Publikationen ist
            ohne ausdrückliche Zustimmung des Autors nicht gestattet.
          </p>
          <p>
            <strong>4. Rechtswirksamkeit dieses Haftungsausschlusses</strong
            ><br />
            Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
            betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern
            Teile oder einzelne Formulierungen dieses Textes der geltenden
            Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
            sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt
            und ihrer Gültigkeit davon unberührt.
          </p>
        </div>
      </div>
    </section>
  </b-modal>
</template>>

<script>
export default {
  props: ["isSiteNoticeModalActive"],
  name: "ModalSiteNoticeModal",
  methods: {
    onModalClose() {
      this.$emit("onCancel");
    },
  },
};
</script>

<style scoped>
</style>