<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>
    <TopNavbar
      @onPrivacyOpenClick="isPrivacyModalActive = true"
      @onSiteNoticeClick="isSiteNoticeModalActive = true"
    />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <p class="title">
            {{ profileTitle }} ({{ profileStart }} - {{ profileEnd }})
          </p>
          <p class="subtitle">{{ employee }}</p>
        </div>
      </div>
      <div class="hero-foot">
        <nav class="tabs is-boxed is-fullwidth">
          <div class="container">
            <ul>
              <li class="is-active has-text-weight-semibold" style="color: #000;">
                <router-link to="/">Übersicht</router-link>
              </li>
              <li class="has-text-weight-semibold" style="color: #000;"><router-link to="/reports">Bericht</router-link></li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <div
              class="
                tile
                box
                is-child
                is-flex
                is-align-items-center
                is-justify-content-center
                is-flex-direction-column
              "
            >
              <span v-if="dayInfoExtra !== ''" class="has-text-grey is-size-5"
                >({{ dayInfoExtra }})</span
              >

              <p class="is-size-1 mb-4 has-text-weight-bold">
                {{ dateCalendarFormated }}
              </p>
              <b-tooltip
                type="is-dark"
                label="Hier kannst Du den Tag auswählen, für welchen Du eine Echtzeitauswertung vornehmen möchtest. Daraufhin ändern sich die Buttons im Feld „Tagesübersicht“. Der Jahreszeitraum bleibt unverändert."
                multilined
              >
                <b-icon icon="info-circle" size="is-small" class="mb-2">
                </b-icon
              ></b-tooltip>
              <b-datepicker
                v-model="dateCalendar"
                :first-day-of-week="1"
                :show-week-number="true"
                :nearby-month-days="false"
                :focusable="false"
                :mobile-native="false"
                :size="calendarSize"
                :min-date="beginCalendar"
                :max-date="endCalendar"
                @input="changeDate"
                @change-month="changeMonth"
                :events="getMarketDates"
              >
                <template v-slot:trigger>
                  <b-button
                    icon-left="calendar-alt"
                    type="is-primary is-primry"
                    size="is-large"
                  />
                </template>
              </b-datepicker>
            </div>
          </div>
          <div class="tile is-5 is-parent">
            <div class="tile is-child box">
              <p class="title is-4">Tagesübersicht</p>
              <div class="columns">
                <div class="column">
                  <div class="box has-background-light is-shadowless">
                    <div class="block has-text-right mb-0">
                      <b-tooltip
                        label="Hier siehst Du die Arbeitszeit des heutigen Tages."
                        multilined
                        type="is-dark"
                      >
                        <b-icon icon="info-circle" size="is-small"> </b-icon
                      ></b-tooltip>
                    </div>
                    <div class="block has-text-centered">
                      <b-icon
                        icon="clock"
                        size="is-large"
                        style="color: #008800"
                      >
                      </b-icon>
                      <p class="is-size-5">
                        {{ dayOfYearWorkMinutesFormated }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="box has-background-light is-shadowless">
                    <div class="block has-text-right mb-0">
                      <b-tooltip
                        label="Hier wird die geleistete Arbeitszeit im Vergleich zur Wochenarbeitszeit dargestellt."
                        multilined
                        type="is-dark"
                      >
                        <b-icon icon="info-circle" size="is-small"> </b-icon
                      ></b-tooltip>
                    </div>
                    <div class="block has-text-centered">
                      <b-icon
                        icon="history"
                        size="is-large"
                        style="color: #008800"
                      >
                      </b-icon>
                      <p class="is-size-5">
                        {{ creditMinutesPerWeekFormated }} /
                        {{ debitMinutesPerWeekFormated }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <div class="box has-background-light is-shadowless">
                    <div class="block has-text-right mb-0">
                      <b-tooltip
                        label="Hier siehst Du die heutige Pausenzeit Deines Beschäftigten."
                        multilined
                        type="is-dark"
                      >
                        <b-icon icon="info-circle" size="is-small"> </b-icon
                      ></b-tooltip>
                    </div>
                    <div class="block has-text-centered">
                      <b-icon
                        icon="coffee"
                        size="is-large"
                        style="color: #c00000"
                      >
                      </b-icon>
                      <p class="is-size-5">
                        {{ dayOfYearPauseMinutesFormated }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="box has-background-light is-shadowless">
                    <div class="block has-text-right mb-0">
                      <b-tooltip
                        label="Das ist das aktuelle Überstundenkonto. Ein positiver Wert spiegelt Überstunden wieder, ein negativer Wert bedeutet Minusstunden."
                        multilined
                        type="is-dark"
                      >
                        <b-icon icon="info-circle" size="is-small"> </b-icon
                      ></b-tooltip>
                    </div>
                    <div class="block has-text-centered">
                      <b-icon
                        icon="chimney"
                        size="is-large"
                        style="color: #c4964d"
                      >
                      </b-icon>
                      <p class="is-size-5">{{ workingTimeAccountFormated }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tile is-3 is-parent">
            <div class="tile is-child box">
              <p class="title is-4">Jahreszeitraum</p>

              <div class="columns">
                <div class="column">
                  <div class="box has-background-light is-shadowless">
                    <div class="block has-text-right mb-0">
                      <b-tooltip
                        label="Dies zeigt Dir den erhaltenen und geplanten Urlaub im Vergleich zum Urlaubsanspruch auf das aktuelle Jahr bezogen."
                        multilined
                        type="is-dark"
                      >
                        <b-icon icon="info-circle" size="is-small"> </b-icon
                      ></b-tooltip>
                    </div>
                    <div class="block has-text-centered">
                      <b-icon
                        icon="island-tropical"
                        size="is-large"
                        style="color: #2b78e4"
                      >
                      </b-icon>
                      <p class="is-size-5">
                        {{ creditHolidaysInMinFormated }} /
                        {{ profileDebitFullHolidaysInMinFormated }} Tag(e)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <div class="box has-background-light is-shadowless">
                    <div class="block has-text-right mb-0">
                      <b-tooltip
                        label="Dies zeigt Dir die erhaltenen und geplanten Schulungstage im Vergleich zum tarifvertraglichen Anspruch auf das aktuelle Jahr bezogen."
                        multilined
                        type="is-dark"
                      >
                        <b-icon icon="info-circle" size="is-small"> </b-icon
                      ></b-tooltip>
                    </div>
                    <div class="block has-text-centered">
                      <b-icon
                        icon="chalkboard-teacher"
                        size="is-large"
                        style="color: #c200e6"
                      >
                      </b-icon>
                      <p class="is-size-5">
                        {{ creditTrainingDaysInMinFormated }} /
                        {{ profileDebitFullTrainingDaysInMinFormated }} Tag(e)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <p class="title is-4">
                Aufzeichnungen <b-tooltip
                  label="Hier siehst Du eine chronologische und detaillierte Auflistung des von Dir ausgewählten Arbeitstages."
                  multilined
                  type="is-dark"
                  ><span class="icon is-small">
                    <i class="far fa-info-circle is-size-6"></i> </span
                ></b-tooltip>
              </p>

              <b-table :data="profileTimes" hoverable striped>
                <b-table-column field="type" label="Typ">
                  <template v-slot:header="{ column }">
                    {{ column.label }}
                  </template>
                  <template v-slot="props">
                    <b-tag
                      :style="{
                        backgroundColor: props.row.color,
                        color: '#fff',
                      }"
                    >
                      <b-icon :icon="props.row.icon"></b-icon
                    ></b-tag>
                  </template>
                </b-table-column>
                <b-table-column field="categorie" label="Kategorie">
                  <template v-slot:header="{ column }">
                    {{ column.label }}
                  </template>
                  <template v-slot="props">
                    <span>
                      {{ props.row.categorie }}
                      <span v-if="props.row.extraInfo !== ''"
                        >({{ props.row.extraInfo }})</span
                      ></span
                    >
                  </template>
                </b-table-column>
                <b-table-column field="begin" label="Beginn">
                  <template v-slot:header="{ column }">
                    {{ column.label }}
                  </template>
                  <template v-slot="props">
                    {{ props.row.begin }}
                  </template>
                </b-table-column>
                <b-table-column field="end" label="Ende">
                  <template v-slot:header="{ column }">
                    {{ column.label }}
                  </template>
                  <template v-slot="props">
                    {{ props.row.end }}
                  </template>
                </b-table-column>
                <b-table-column field="duration" label="Dauer">
                  <template v-slot:header="{ column }">
                    {{ column.label }}
                  </template>
                  <template v-slot="props">
                    {{ props.row.duration }}
                  </template>
                </b-table-column>

                <template slot="empty">
                  <div class="has-text-centered py-5" v-if="!isLoading">
                    Es sind keine Daten vorhanden!
                  </div>
                  <div class="has-text-centered py-5" v-else>
                    Daten werden geladen!
                  </div>
                </template>
              </b-table>
            </article>
          </div>
        </div>
      </div>
    </section>
    <ModalPrivacy
      :isPrivacyModalActive="isPrivacyModalActive"
      @onCancel="onPrivacyModalClose"
    />

    <ModalSiteNotice
      :isSiteNoticeModalActive="isSiteNoticeModalActive"
      @onCancel="onSiteNoticeModalClose"
    />
  </div>
</template>

<script>
import TopNavbar from "@/components/TopNavbar.vue";
import ModalPrivacy from "@/components/ModalPrivacy.vue";
import ModalSiteNotice from "@/components/ModalSiteNotice.vue";

import { createNamespacedHelpers } from "vuex";

import _ from "lodash";

const { mapState } = createNamespacedHelpers("home");

export default {
  name: "HomePage",
  components: {
    TopNavbar,
    ModalPrivacy,
    ModalSiteNotice,
  },
  data() {
    return {
      isPrivacyModalActive: false,
      isSiteNoticeModalActive: false,
    };
  },
  methods: {
    UTCToDate(value) {
      const date = new Date(value);
      return new Intl.DateTimeFormat("de-DE", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(date);
    },
    setStartDate() {
      const now = new Date();
      const profileStart = new Date(this.$store.getters.profile.start);

      if (_.isNull(this.dateCalendar)) {
        if (now.getFullYear() === profileStart.getFullYear()) {
          this.dateCalendar = now;
          this.monthCalendar = now.getMonth() + 1;
        } else {
          this.dateCalendar = profileStart;
          this.monthCalendar = profileStart.getMonth() + 1;
        }
      } else {
        this.monthCalendar = this.dateCalendar.getMonth() + 1;
      }
    },
    list: function () {
      const { dateCalendar, monthCalendar } = this;

      const selectedDate = this.UTCToDate(dateCalendar);

      this.$store
        .dispatch("home/list", {
          selectedDate,
          selectedMonth: monthCalendar,
        })
        .catch((response) => {
          this.$buefy.toast.open({
            message: response.data.payload.errorMsg,
            type: "is-danger",
          });
        });
    },
    changeDate() {
      this.list();
    },
    changeMonth(value) {
      this.monthCalendar = value + 1;

      this.list();
    },
    formatMinutes(minutes) {
      if (minutes >= 0) {
        const h = Math.floor(minutes / 60);
        const m = minutes - h * 60;
        return (h < 10 ? "0" + h : h) + "h " + (m < 10 ? "0" + m : m) + "m";
      } else {
        const h = Math.floor(Math.abs(minutes) / 60);
        const m = Math.abs(minutes) - h * 60;
        return (
          "-" + (h < 10 ? "0" + h : h) + "h " + (m < 10 ? "0" + m : m) + "m"
        );
      }
    },
    onPrivacyModalClose() {
      this.isPrivacyModalActive = false;
    },
    onSiteNoticeModalClose() {
      this.isSiteNoticeModalActive = false;
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.loading,
      data: (state) => state.data,
    }),
    dateCalendar: {
      get() {
        return this.$store.state.home.dateCalendar;
      },
      set(value) {
        this.$store.commit("home/setDateCalendar", value);
      },
    },
    monthCalendar: {
      get() {
        return this.$store.state.home.monthCalendar;
      },
      set(value) {
        this.$store.commit("home/setMonthCalendar", value);
      },
    },
    dateCalendarFormated() {
      if (_.isNull(this.dateCalendar)) {
        return "-";
      }

      return this.UTCToDate(this.dateCalendar);
    },
    getMarketDates() {
      if (_.isEmpty(this.data)) {
        return [];
      }

      return this.data.markedDates.map((item) => {
        return { type: item.type, date: new Date(item.date) };
      });
    },
    employee() {
      return this.$store.getters.employee;
    },
    profileTitle() {
      return this.$store.getters.profile.title;
    },
    profileStart() {
      return this.UTCToDate(this.$store.getters.profile.start);
    },
    profileEnd() {
      return this.UTCToDate(this.$store.getters.profile.end);
    },
    beginCalendar() {
      return new Date(this.$store.getters.profile.start);
    },
    endCalendar() {
      return new Date(this.$store.getters.profile.end);
    },
    dayInfoExtra() {
      if (_.isEmpty(this.data)) {
        return "";
      }

      return this.data.dayInfoExtra;
    },
    profileDebitFullHolidaysInMinFormated() {
      return (
        parseInt(this.$store.getters.profile.debitFullHolidaysInMin) /
        parseInt(this.$store.getters.profile.debitMinutesPerDay)
      )
        .toFixed(2)
        .replace(/\./, ",");
    },
    profileDebitFullTrainingDaysInMinFormated() {
      return (
        parseInt(this.$store.getters.profile.debitFullTrainingDaysInMin) /
        parseInt(this.$store.getters.profile.debitMinutesPerDay)
      )
        .toFixed(2)
        .replace(/\./, ",");
    },
    creditHolidaysInMinFormated() {
      if (_.isEmpty(this.data)) {
        return "--";
      }

      return (
        parseInt(this.data.creditHolidaysInMin) /
        parseInt(this.$store.getters.profile.debitMinutesPerDay)
      )
        .toFixed(2)
        .replace(/\./, ",");
    },
    creditTrainingDaysInMinFormated() {
      if (_.isEmpty(this.data)) {
        return "--";
      }

      return (
        parseInt(this.data.creditTrainingDaysInMin) /
        parseInt(this.$store.getters.profile.debitMinutesPerDay)
      )
        .toFixed(2)
        .replace(/\./, ",");
    },
    dayOfYearWorkMinutesFormated() {
      if (_.isEmpty(this.data)) {
        return "--";
      }

      return this.formatMinutes(this.data.dayOfYearWorkMinutes);
    },
    creditMinutesPerWeekFormated() {
      if (_.isEmpty(this.data)) {
        return "--";
      }

      return this.formatMinutes(this.data.creditMinutesPerWeek);
    },
    debitMinutesPerWeekFormated() {
      if (_.isEmpty(this.data)) {
        return "--";
      }

      return this.formatMinutes(this.data.debitMinutesPerWeek);
    },
    dayOfYearPauseMinutesFormated() {
      if (_.isEmpty(this.data)) {
        return "--";
      }

      return this.data.dayOfYearPauseMinutes + "m";
    },
    workingTimeAccountFormated() {
      if (_.isEmpty(this.data)) {
        return "--";
      }

      return this.formatMinutes(this.data.workingTimeAccount);
    },
    profileTimes() {
      if (_.isEmpty(this.data)) {
        return [];
      }

      return this.data.times;
    },
    isMobilScreen() {
      return screen.width < 500 ? true : false;
    },
    calendarSize() {
      return this.isMobilScreen ? "is-small" : "is-medium";
    },
  },
  created: function () {
    this.setStartDate();
  },
  mounted: function () {
    this.list();
  },
};
</script>

<style>
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-work {
  background-color: #008800;
}
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-type1 {
  background-color: #ffa500;
}
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-type2 {
  background-color: #2b78e4;
}
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-type3 {
  background-color: #9e89ff;
}
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-type4 {
  background-color: #00c7c7;
}
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-type5 {
  background-color: #c200e6;
}
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-type6 {
  background-color: #fa8072;
}
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-type7 {
  background-color: #9acd32;
}
.datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-custom-color-type8 {
  background-color: #000000;
}
</style>