<template>
  <section class="hero is-fullheight has-background-dark">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-one-third is-offset-one-third">
            <div class="box has-background-white">
              <article class="media">
                <div class="media-content">
                  <div class="content has-text-centered">
                    <p>Abmeldung erfolgt!</p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LogoutPage",
  data() {
    return {};
  },
  methods: {
    logout: function () {
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$store.commit("home/setDateCalendar", null);
          this.$store.commit("home/setMonthCalendar", null);
          this.$router.push("/login/-");
        })
        .catch((response) => {
          this.$buefy.toast.open({
            message: response.data.payload.errorMsg,
            type: "is-danger",
          });
        });
    },
  },

  mounted: function () {
    this.logout();
  },
};
</script>

<style>
</style>