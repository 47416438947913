import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from "buefy";
import "./assets/css/nero-buefy.css"
import axios from "axios";

Vue.use(Buefy, {
  defaultIconPack: "far",
  defaultDialogCancelText: "Abbrechen",
  defaultToastDuration: 3000,
  defaultToastPosition: "is-top",
  defaultTabsAnimated: false,
});

Vue.config.productionTip = false


const loginDataJson = localStorage.getItem("loginData");
if (loginDataJson) {
  const loginData = JSON.parse(loginDataJson);
  axios.defaults.headers.common["Authorization"] = loginData.token;
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
